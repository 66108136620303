import { Fragment } from "react";
import { valueFormat } from "src/helper/valueFormat";

const OrderProduct = ({ product }) => {
  return (
    <>
      <tr style={{ marginBottom: "3px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
            className="product product-name-longer"
          >
            <p
              style={{
                width: "30px",
              }}
            >{`${product.quantity}x `}</p>
            <span>{valueFormat(product?.name)}</span>
          </div>
          <p className="product product-longer-price">
            {valueFormat(`$ ${Number(product?.price).toFixed(2)}`)}
          </p>
        </div>
      </tr>
      {product?.modifier &&
        product?.modifier.length > 0 &&
        product?.modifier.map((mod) => {
          return (
            <Fragment key={mod?._id}>
              <tr>
                <td className="modifier-main-name">{`${mod?.name?.toUpperCase()}`}</td>
              </tr>
              {mod &&
                mod?.list.length > 0 &&
                mod?.list.map((item) => {
                  return (
                    <tr key={item?._id}>
                      <td className="modifier-name">
                        {item?.count ? <b>{`${item?.count}x `}</b> : <></>}
                        {`${item?.name}`}{" "}
                        {item?.freeCount ? <b>({item.freeCount} free)</b> : ""}
                      </td>
                      <td className="modifier-price">
                        {item?.isFree
                          ? "-"
                          : valueFormat(`$ ${Number(item?.price).toFixed(2)}`)}
                      </td>
                    </tr>
                  );
                })}
            </Fragment>
          );
        })}
    </>
  );
};
export default OrderProduct;
