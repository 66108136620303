import axiosInstance from "./axios";

const getOrderData = async (businessId, orderId, env = "stage") => {
  try {
    let url = `/orders/details/${businessId}/${orderId}`
    if (env !== "prod") {
      url = "https://stage-gateway.orders.co/api01".concat(url)
    }
    return axiosInstance.get(url)
  } catch (e) {
    throw new Error(e.message)
  }
}

const orderService = {
  getOrderData,
}

export default orderService
