import { memo } from "react";
const OrderHeader = ({ orderData }) => {
  const TYPES = {
    pickup: "Pick-up",
    delivery: "Delivery",
  };

  return (
    <div id="receiptHeader">
      <div id="receiptHeaderInfo">
        <p className="companyName">
          {orderData?.business[0]?.title || orderData?.business[0]?.companyName}
        </p>
        <hr
          style={{
            marginBottom: "16px",
            marginTop: "16px",
          }}
        />
        <p className="user-message">
          {`${
            orderData?.contactInfo?.firstName || orderData?.contactInfo?.name
          }!`}
        </p>
        <p className="order-number">{`Order #${orderData.number}`}</p>
        <hr
          style={{
            marginBottom: "16px",
            marginTop: "16px",
          }}
        />
        <p
          style={{
            fontSize: "24px",
            lineHeight: "26px",
            letterSpacing: "0.1px",
          }}
        >
          {TYPES[orderData?.type]}
        </p>
        <hr
          style={{
            marginTop: "16px",
          }}
        />
      </div>
    </div>
  );
};
export default memo(OrderHeader);
