export const groupByCategory = (data) => {
  return data?.reduce((acc, item) => {
    if (item?.category) {
      const categoryId = item.category.name;
      if (!acc[categoryId]) {
        acc[categoryId] = {
          items: [],
          categoryName: item.category.name,
        };
      }
      acc[categoryId].items.push(item);
    } else {
      acc[item._id] = {
        items: [],
      };
      acc[item._id].items.push(item);
    }
    return acc;
  }, []);
};
