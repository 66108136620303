import BusinessIcon from 'src/images/businessIcon.png';
import AddressIcon from 'src/images/address.png';
import TimeIcon from 'src/images/time.png';
import moment from "moment";
const OrderTypeDescription = {
  PICKUP: 'Pick up from:',
  DELIVERY: 'Deliver to:',
  CURBSIDE: 'Curbside pickup from:'
}

const OrderDetails = ({ orderData, timeZoneId }) => {

  const businessTitle = orderData.business[0]?.companyName || orderData.business[0]?.title
  const businessAddress = orderData.business[0]?.addressInfo?.fullAddress || ''

  const orderType = orderData.type || ''
  const orderTypeDescription = OrderTypeDescription[orderType.toUpperCase()] || ''

  return (
    <div className="order-details">
      <p className="order-details-title">Order details:</p>
      <div className="order-details-business">
        <div>
          <img src={BusinessIcon} alt="business-icon" />
        </div>
        <div className="order-details-address">
          <p className="order-details-address-name">{businessTitle}</p>
          <p className="order-details-address-currentAddress">{businessAddress}</p>
        </div>
      </div>
      <div className="order-details-business">
        <div>
          <img src={AddressIcon} alt="address-icon" />
        </div>
        <div className="order-details-address">
          <p className="order-details-address-name">{orderTypeDescription}</p>
          <p className="order-details-address-currentAddress">
            {orderData.type === 'pickup' ? (
              <>
                {orderData.business[0]?.addressInfo?.fullAddress}
              </>
            ) : (
              <>
                {orderData.delivery?.deliveryAddress?.fullAddress}
              </>
            )}
          </p>
        </div>
      </div>
      <div className="order-details-business">
        <div>
          <img src={TimeIcon} alt="time-icon" />
        </div>
        <div className="order-details-address">
          <p className="order-details-address-name">Be ready:</p>
          <p className="order-details-address-currentAddress">
            {moment(orderData?.beReadyTime)?.tz(timeZoneId)
              .format("hh:mm A - ddd, MMM Do")}
          </p>
        </div>
      </div>
      <hr className="line-header" />
    </div>
  )
}
export default OrderDetails